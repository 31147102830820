import currentLanguageSelector from './languageSelectors';

export const footerNavigationItemsSelector = (state: AppState) => {
  const currentLanguage = currentLanguageSelector(state);
  return state.globals[currentLanguage].footer.navigation;
};

export const footerPreviewIdSelector = (state: AppState) => {
  const currentLanguage = currentLanguageSelector(state);
  return state.globals[currentLanguage].footer.previewId;
};
