// components
import Footer from '../../components/Footer/Footer';

// utils
import { LayoutProps } from './layout.types';
import { classNameBuilder } from '../../utils/classNameBuilder';

export default function Layout({
  children,
  className,
}: Readonly<React.PropsWithChildren<LayoutProps>>) {
  return (
    <>
      <main className={classNameBuilder(className, 'gdds')}>{children}</main>
      <Footer />
    </>
  );
}
