import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';

// utils
import { useGdds } from 'utils/hooks/use-gdds';

// workaround was implemented because no event is triggered for nordics based projects when the user changes cookies

export function useUsercentricsWorkaround() {
  const [ucOpen, setUcOpen] = useState(false);
  const router = useRouter();
  const isGdds = useGdds();

  const handleUcOverlayClick = useCallback(() => {
    setTimeout(() => {
      const ucRoot = document.querySelectorAll('#usercentrics-root');
      const ucOverlay = ucRoot[0]?.shadowRoot?.querySelector('[data-testid="uc-overlay"]');
      if (!ucOverlay) {
        setUcOpen(false);
        router.refresh();
      }
    }, 500);
  }, [router]);

  const reloadIfGdds = () => {
    if (isGdds) {
      router.refresh();
    }
  };

  useEffect(() => {
    if (!isGdds) {
      return;
    }
    if (ucOpen) {
      window?.addEventListener('click', handleUcOverlayClick);
    }
    return () => {
      window?.removeEventListener('click', handleUcOverlayClick);
    };
  }, [handleUcOverlayClick, isGdds, ucOpen]);

  return { setUcOpen, reloadIfGdds };
}
