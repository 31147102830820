import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';
import { ErrorPage } from '@geberit/gdds';
import striptags from 'striptags';
import key from 'weak-key';

// styles
import styles from './not-found.module.scss';

// components
import Layout from '../layout';

// utils
import { errorPageSelector, globalsSearchSelector } from 'utils/selectors/globalsSelectors';
import { translationByKeySelector } from 'utils/selectors/translationSelectors';
import { handlePopup } from 'utils/openInPopup';

function NotFound(props) {
  const page = useSelector(errorPageSelector);
  const { links, headline, subheadline, text } = page || {};
  const searchText = useSelector((s) => translationByKeySelector(s, 'web20_search_placeholder'));
  const submitText = useSelector((s) => translationByKeySelector(s, 'web20_search_submit_label'));
  const searchConfig = useSelector(globalsSearchSelector);
  const router = useRouter();

  const handleSearchSubmit = (searchConfig) => (e) => {
    if (!searchConfig) {
      return;
    }

    const input = e.target?.value || e;
    const query = striptags(input);

    router.push(`${searchConfig.searchResultPageUrl}?q=${query}`);
    return false;
  };

  const onLinkClicked = (e, link) => {
    // open in new window
    if (
      link.window !== '_popup' &&
      (link.type === 'external_link' || (link.type === 'internal_link' && link.window === '_blank'))
    ) {
      return;
    }
    e.preventDefault();

    // popup
    if (link.window === '_popup') {
      return handlePopup(link, key(link));
    }

    // same window and internal link
    router.push(link.target);
  };

  // Related Task #320834
  const stripedHeadline = headline?.replace(/<[^>]+>/gi, '') ?? '';

  return (
    <Layout {...props} className={styles.errorWrapper}>
      <ErrorPage
        errorType="404"
        mainTitle={stripedHeadline}
        secondTitle={subheadline}
        errorDescription={text ?? ''}
        searchProps={{
          buttonProps: { children: submitText },
          onSubmit: handleSearchSubmit(searchConfig),
          placeholder: searchText,
        }}
        links={links?.map((link) => ({
          ...link,
          key: 'link-' + key(link),
          windowTarget: link.window,
          hasArrow: link.showArrow,
          onClick: (e) => onLinkClicked(e, link),
        }))}
      />
    </Layout>
  );
}

export default NotFound;
