'use client';

import { useSelector } from 'react-redux';
import key from 'weak-key';

// components
import NordicsNotFound from '../Nordics/error/not-found';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import Footer from 'components/Footer/Footer';
import SearchBar from 'components/SearchBar/SearchBar';
import { CmsLink } from 'components/Link/CmsLink';

// utils
import { errorPageSelector } from 'utils/selectors/globalsSelectors';
import { useNord } from 'utils/hooks/use-nord';
import { isEmpty } from 'utils/is-empty';

export function NotFound() {
  const page = useSelector(errorPageSelector) ?? null;
  const { links, headline, subheadline, image, text } = page ?? {};
  const isNordics = useNord();

  if (isNordics) {
    return <NordicsNotFound />;
  }

  return (
    <>
      <main>
        <div className="c-error-page" style={{ backgroundImage: `url(${image})` }}>
          <div className="grid-container">
            <div className="c-error-page__content">
              {subheadline && <InnerHtml as="span" content={subheadline} />}
              {headline && <InnerHtml as="h1" content={headline} className="h2" />}
              {text && <InnerHtml as="p" content={text} />}
              <div className="c-error-page__search">
                <SearchBar />
              </div>
              {!isEmpty(links) && (
                <ul className="link-list">
                  {links.map((link) => (
                    <li key={key(link)}>
                      <CmsLink link={link} />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
