import { useRef } from 'react';
import { useSelector } from 'react-redux';
import striptags from 'striptags';
import { useRouter } from 'next/navigation';

// components
import SearchAutocomplete from './SearchAutocomplete';

// utils
import { globalsSearchSelector } from 'utils/selectors/globalsSelectors';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { isEmpty } from 'utils/is-empty';

interface SearchBarProps {
  initialQuery?: string;
  customHandler?: (query: string) => void;
}

export default function SearchBar({ initialQuery, customHandler }: Readonly<SearchBarProps>) {
  const searchConfig = useSelector(globalsSearchSelector);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const translate = useTranslationFunction();

  if (!searchConfig) return null;
  const { searchResultPageUrl } = searchConfig;

  const handleSearchSubmit = (e) => {
    const input = e.target?.value || (typeof e === 'string' && e) || '';
    const query = striptags(input);

    if (customHandler) {
      customHandler(query);
      return;
    }

    if (isEmpty(input)) {
      return;
    }

    router.push(`${searchResultPageUrl}?q=${query}`);
  };

  return (
    <form
      className="c-search-bar-form c-search-bar-form--grey-bg"
      onSubmit={(e) => {
        e.preventDefault();
        handleSearchSubmit(searchInputRef.current?.value);
      }}
      action={searchResultPageUrl}
    >
      <SearchAutocomplete
        id="search-bar"
        handleSubmit={handleSearchSubmit}
        searchPlaceholder={translate('web20_search_placeholder')}
        initialQuery={initialQuery}
        searchInputRef={searchInputRef}
        hasAnimation={false}
      />
    </form>
  );
}
