import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';
import { Footer, Icons } from '@geberit/gdds';
import key from 'weak-key';

// Components
import { EditWrapper } from '../ContentCreator/EditWrapper';
import { SearchIndexOff } from '../SearchIndex/SearchIndexOff';
import { TPP_TYPE_TECHNICAL } from '../ContentCreator/tppConstants';

// Styles
import styles from './gddsFooter.module.scss';

// Utils
import { usercentricsSettingsId } from '../../utils/selectors/globalsSelectors';
import { useTracking } from '../../utils/hooks/useTracking';
import { footerTrackingAction } from './trackingActions';
import { useNord } from '../../utils/hooks/use-nord';
import { gridSizes, buildSize } from '../../utils/gridSize';
import { handlePopup } from '../../utils/openInPopup';
import { useTranslationFunction } from '../../utils/hooks/use-translations';
import { classNameBuilder } from '../../utils/classNameBuilder';
import { useUsercentricsWorkaround } from '../../features/cookie-consent/hooks/use-usercentrics-workaround';

function GddsFooter({ toTopButton, socialMediaItems, previewId, navigationItems }) {
  const track = useTracking();
  const footerRef = useRef<HTMLDivElement | null>(null);
  const router = useRouter();
  const isNordics = useNord();
  const translate = useTranslationFunction();
  const uc_settingsId = useSelector(usercentricsSettingsId);
  const { setUcOpen } = useUsercentricsWorkaround();

  const onClickNaviItem = (naviItem) => {
    if (naviItem.url?.includes('http://') || naviItem.url?.includes('https://')) {
      if (naviItem.window === '_popup') {
        const windowId = key(naviItem);
        handlePopup({ target: naviItem.url, naviItem }, windowId);
      } else {
        window.open(naviItem.url, naviItem.window);
      }
    } else if (naviItem.type !== 'cookie_disclaimer') {
      router.push(naviItem.url);
    } else if (uc_settingsId) {
      window.UC_UI.showFirstLayer();
      setUcOpen(true);
    }

    track.trackEvent(footerTrackingAction('Navigation', naviItem.label));
  };

  const onClickSocial = (name, target) => {
    track.trackEvent(footerTrackingAction('SocialNavigation', name));
    window.open(target);
  };

  return (
    <SearchIndexOff>
      <EditWrapper previewId={previewId} editType={TPP_TYPE_TECHNICAL}>
        <div className={styles.footer} ref={footerRef}>
          <Footer
            maxContentWidth={buildSize(isNordics ? gridSizes.gddsFullGrid : gridSizes.full)}
            copyright={{ text: translate('web20_page_copy') }}
            regionSelect={
              isNordics
                ? {
                    title: translate('web20_footer_country_selector_label'),
                    region: translate('web20_current_country'),
                  }
                : {}
            }
            socialNavigation={{
              items:
                socialMediaItems
                  ?.filter((item) => item.url)
                  .map((item) => {
                    const icon = item.type;
                    return {
                      color: '#000',
                      symbol: Icons[icon] ? icon : 'Bullet',
                      onClick: () => onClickSocial(icon, item.url),
                    };
                  }) || [],
            }}
            metaNavigation={{
              items:
                navigationItems?.map((item) => ({
                  title: item.label,
                  onClick: () => onClickNaviItem(item),
                  className:
                    item.type === 'cookie_disclaimer'
                      ? classNameBuilder('footer-cookie-link', !uc_settingsId && 'ucShow_e7Q5KaN7e')
                      : undefined,
                })) || [],
            }}
            toTopButton={toTopButton}
          />
        </div>
      </EditWrapper>
    </SearchIndexOff>
  );
}

export default GddsFooter;
